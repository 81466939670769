<template>
  <v-container
    fill-height
    fluid
  >
    <v-row>
      <v-col
        align="center"
        justify="center"
      >
        <div>
          <v-alert
            outlined
            type="warning"
            border="top"
            max-width="800"
            class="text-left"
          >
            <b>Beklager! Du har ikke adgang til denne side.  </b>
            <br>
            Det skyldes, at den rolle, du er tildelt i systemet, ikke har rettigheder til det.  
            <br>
            Hvis du mener, det er en fejl, kontakt din nærmeste leder.
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Reports",
};
</script>